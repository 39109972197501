import React, {useEffect, useContext} from 'react'
import { ThemeContext } from '../App'

export default function Row({guess, currentGuess, isCorrect}) {

  const { theme } = useContext(ThemeContext)
  const darkTheme = theme === "darkTheme"
  console.log("Current theme in Row:", theme);

  useEffect(() => {
    // This code runs when `theme` changes.
    // Any state updates or operations you need to perform when the theme changes can go here.
    // For now, this effect is just a trigger for re-rendering when the theme changes.
  }, [theme]); // Dependency array with `theme`. The effect runs when `theme` changes.
  
  if(isCorrect && guess){
    return (
      <div className={"row won"}>
        {guess.map((l, i) => (
          <div key={i} className={l.color}>{l.key}</div>
        ))}
      </div>
    )
  }

  if(guess){
      return (
        <div className="row past">
          {guess.map((l, i) => (
              <div key={i} className={l.color}> {l.key} </div>
          ))}
        </div>
      )
    }
    if(currentGuess){
      let letters = currentGuess.split('')
      return (
        <div className="row current">
          {letters.map((letter, i) =>  (
            <div key={i} className='filled'>{letter}</div>
          ))}
          {[...Array(5 - letters.length)].map((_, i) => (
            <div key={i}></div>
          ))}
        </div>
      )
    }

    return (
      <div className="row">
        <div style={{background: theme === 'dark' ? 'rgba(0,0,0,.65)' : 'rgba(255,255,255,.65)'}}></div>
        <div style={{background: theme === 'dark' ? 'rgba(0,0,0,.65)' : 'rgba(255,255,255,.65)'}}></div>
        <div style={{background: theme === 'dark' ? 'rgba(0,0,0,.65)' : 'rgba(255,255,255,.65)'}}></div>
        <div style={{background: theme === 'dark' ? 'rgba(0,0,0,.65)' : 'rgba(255,255,255,.65)'}}></div>
        <div style={{background: theme === 'dark' ? 'rgba(0,0,0,.65)' : 'rgba(255,255,255,.65)'}}></div>
      </div>
    )
  }

