import React, { useEffect, useState, createContext } from 'react'
import Wordle from './components/Wordle'
import data from './data/db.json'
import Logo from './components/Logo'
import ReactSwitch from 'react-switch';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSun } from '@fortawesome/free-solid-svg-icons'
import { faMoon } from '@fortawesome/free-solid-svg-icons'
import { ToastContainer } from 'react-toastify';

export const ThemeContext = createContext("light");

function App() {
  const [solution, setSolution] = useState("dark")
  const [theme, setTheme] = useState("dark")
  const sun = <FontAwesomeIcon icon={faSun}/>
  const moon = <FontAwesomeIcon icon={faMoon}/>

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("cTheme", newTheme);
  }
  
  useEffect(()=> {
    let userTheme = localStorage.getItem("cTheme");
    console.log('userTheme from useEffect: ', userTheme)
    if(userTheme === null || userTheme === undefined) {
      userTheme = "light"
      localStorage.setItem("cTheme", userTheme);
    }
    setTheme(userTheme)
  }, [])

  useEffect(() => {
    const words = data.solutions
        const randomSolution = words[Math.floor(Math.random() * words.length)]
        setSolution(randomSolution.word);
  }, [setSolution])


  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div  className={`App ${theme === 'light' ? 'light-theme-stripe' : 'dark-theme-stripe'}`} 
       id={theme}
       style={{
        backgroundColor: theme === 'dark' ? 'black' : 'whitesmoke',
        height: '100vh',
       }}
      >
      
        <div className="header-container">
        <div className="logo">
        <Logo fill={theme === "dark" ? "var(--clr-dkTheme)" : "var(--clr-dk-grey)"} 
        /></div>
        <ReactSwitch 
          onChange={toggleTheme}
          checked={theme === "dark"}
          checkedIcon={moon}
          uncheckedIcon={sun}
          offColor="#333"
          onColor="#333"
          offHandleColor="#888"
          onHandleColor="#666"
          className="switch"
        />
        </div>
      
      {/* <h2>solution: {solution}</h2> */}
      <ToastContainer />
      {solution && <Wordle solution={solution} />}
      </div>
  
    </ThemeContext.Provider>
  )
}

export default App